import {Flex, Image, Text} from "@chakra-ui/react";
import tank from "../../../assets/images/tank.svg";
import {useTranslation} from "react-i18next";

const MyCanister = ({volume}) => {
    const {t} = useTranslation("General");
    return (
        <>
            <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
            >
                <Image
                    src={tank}
                    alt="Kanister"
                    w={'100px'}
                    pb={'16px'}
                />
                <Text fontSize="14px" fontWeight="500" lineHeight={'32px'}>
                    {t('CONTAINER_LABEL')}:
                </Text>
                <Text fontSize="3xl" fontWeight="bold" color="#323352">
                    {volume} l
                </Text>
            </Flex>
        </>
    );
}
export default MyCanister;
