import './footer.css'
import {FaFacebookSquare} from "react-icons/fa";
import {FaLinkedin, FaTwitter} from "react-icons/fa6";
import {FiPhone} from "react-icons/fi";
import {Box, Divider, Flex, HStack, Icon, Image, ListItem, Stack, Text, UnorderedList, VStack} from "@chakra-ui/react";
import LinkTo from "../../typography/Link";
import {EnvelopeIcon} from "@heroicons/react/24/solid";
import logo from "../../../assets/images/logo_light.png"
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation("General");

    const usefulLinks = [
        {
            label: t('HOW_IT_WORKS'),
            url: '#'
        },
        {
            label: t('RULES'),
            url: '#'
        },
        {
            label: t('CONTACT'),
            url: '#'
        },
        {
            label: t('FOR_PARTNERS'),
            url: '#'
        },
        {
            label: t('STATUTE'),
            url: '#'
        },
        {
            label: t('PRIVACY_POLICY'),
            url: '#'
        },
    ]

    return (
        <Flex py={4} bg={'primary'} px={{base: 4, sm: 0}} w={'100%'}>
            <Box display={{base: 'flex', sm: 'none'}} flexDirection={'column'} alignItems={'center'}>
                <VStack alignItems={"left"} textAlign={"left"}>
                    <UnorderedList styleType={"none"}
                                   ml={0}
                                   display="flex"
                                   flexWrap="wrap"
                                   justifyContent="space-between">
                        {usefulLinks.map((item, index) => {
                            return (
                                    <ListItem key={index} py={2} width="48%">
                                        <LinkTo textDecoration={'none'} color={'white'} address={item.url}>
                                            <Text>{item.label}</Text>
                                        </LinkTo>
                                    </ListItem>
                            )
                        })}
                    </UnorderedList>
                </VStack>
                <VStack py={6}>
                    <Image src={logo} w={24}/>
                    <VStack py={2} color={'green'}>
                        <HStack>
                            <Icon as={EnvelopeIcon} fontSize={24}/>
                            <Text>kontakt@mamtanio.com</Text>
                        </HStack>
                        <HStack>
                            <Icon as={FiPhone} fontSize={22}/>
                            <Text>+48 123 123 123</Text>
                        </HStack>
                    </VStack>
                    <VStack color={'white'} rowGap={0}>
                        <Text>ul. Przykładowa 1</Text>
                        <Text>32-123 Gdańsk</Text>
                    </VStack>
                    <Text color={'secondary'}>{t('LAWS')} © MamTanio.com</Text>
                </VStack>
            </Box>
            <Box display={{base: 'none', sm: 'flex'}} mx='auto' w={{base: '100%', sm: '7xl'}} flexDirection={'column'}>
                <Flex flexGrow={1} flexDirection={'row'} justifyContent={'space-between'} py={4}>
                    <VStack alignItems={'left'}>
                        <Image src={logo} w={120}/>
                        <VStack alignItems={'left'} py={4} color={'green'}>
                            <HStack>
                                <Icon as={EnvelopeIcon} fontSize={24}/>
                                <Text>kontakt@mamtanio.com</Text>
                            </HStack>
                            <HStack>
                                <Icon as={FiPhone} fontSize={22}/>
                                <Text>+48 123 123 123</Text>
                            </HStack>
                        </VStack>
                        <VStack alignItems={'left'} textAlign={'left'} color={'white'} rowGap={0}>
                            <Text>ul. Przykładowa 1</Text>
                            <Text>32-123 Gdańsk</Text>
                        </VStack>
                    </VStack>
                    <VStack alignItems={"left"} textAlign={"left"}>
                        <Text color={'secondary'}>{t('LINKS')}</Text>
                        <UnorderedList styleType={"none"} ml={0}>
                            {usefulLinks.map((item, index) => {
                                return <ListItem key={index} py={1}>
                                    <LinkTo textDecoration={'none'} color={'white'} address={item.url}>
                                        <Text>{item.label}</Text>
                                    </LinkTo>
                                </ListItem>
                            })}
                        </UnorderedList>
                    </VStack>
                </Flex>
                <Divider borderColor={'secondary'}/>
                <Flex py={6} justifyContent={'space-between'}>
                    <Text color={'white'}>{t('LAWS')} © MamTanio.com</Text>
                    <HStack color={'green'}>
                        <Text color={'white'}>{t('FIND_US')}</Text>
                        <Icon as={FaLinkedin} fontSize={24}/>
                        <Icon as={FaFacebookSquare} fontSize={24}/>
                        <Icon as={FaTwitter} fontSize={24}/>
                    </HStack>
                </Flex>
            </Box>
        </Flex>
    )
}

export default Footer;
