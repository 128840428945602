import {Box, Button, Flex, HStack, Image, Text, useDisclosure} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import utils from "../../helpers/FormatHelpers";
import {useMemo} from "react";

const MyPlanListItem = ({item}) => {
    const {t} = useTranslation("PlanPage");
    const navigate = useNavigate();

    const expTime = useMemo(() => {
        return utils.dateFormat(item?.expirationDateUtc);
    }, [item]);

    const handleRealize = () => {
        navigate('/realize', {state: item});
    }

    return (
        <>
            <Flex bg={'#F0F9FD'} p={4} borderRadius="12px" w={'full'} justifyContent={'center'}
                flexDirection={'column'} alignItems={'center'} p={4} boxShadow="md" fontFamily="Ubuntu, sans-serif">
                <Flex alignItems="center" justifyContent="space-between" mb={4} w={'full'}>
                    <Flex alignItems="center" gap={2}>
                        <Box
                            bg="#2A1E8A"
                            color="white"
                            mr={2}
                            borderRadius="50%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <rect width="50" height="50" rx="25" fill="#011938"/>
                                <path d="M19.0652 25C19.0652 20.1675 22.608 16.25 26.9783 16.25H31.5V35H19.0652V25Z" fill="white"/>
                                <path d="M18.5 18.0673L21.135 15.625L23.6767 18.9778L21.0417 21.4202L18.5 18.0673Z" fill="white"/>
                                <path d="M25.2826 15H29.8043V19.375H25.2826V15Z" fill="white"/>
                            </svg>
                        </Box>
                        <Text fontWeight="500" fontSize="32px" color="#323352">
                            {item?.litersUsed} l
                        </Text>
                    </Flex>
                    <Box textAlign="right">
                        <Text fontSize="12px" color="#9495A9">
                            {t('WILL_EXPIRE')}
                        </Text>
                        <Text fontSize="14px" fontWeight="600" color="#323352">
                            {expTime}
                        </Text>
                    </Box>
                </Flex>
                <Button
                    bg="#2FEA52"
                    color="black"
                    borderRadius="24px"
                    width="100%"
                    fontWeight="600"
                    fontSize="16px"
                    _hover={{ bg: "#28C94C" }}
                    onClick={handleRealize}
                >
                    {t('REALIZE')}
                </Button>
            </Flex>
        </>
    )
}

export default MyPlanListItem;
