import {
    Box, Text,
    Flex, VStack,
} from "@chakra-ui/react";
import OfferListItem from "../../components/comps/OfferListItem/OfferListItem";
import {useEffect, useState} from "react";
import OffersService from "../../api/services/OffersService";
import {apiClient} from "../../api/apiClient";
import SelectInput from "../../components/comps/SelectInput";
import {useAuth} from "../../providers/AuthProvider";
import FuelWidget from "../../components/widget/FuelWidget";
import {HiOutlineLocationMarker} from "react-icons/hi";
import carLogos from "../../assets/images/carbrands";
import {useTranslation} from "react-i18next";

const AllOffersPage = () => {
    const {user} = useAuth();
    const [offers, setOffers] = useState([]);
    const [carBrands, setCarBrands] = useState([]);
    const [brandOffers, setBrandOffers] = useState([]);
    const [offerLocations, setOfferLocations] = useState([]);
    const [selectedCarBrand, setSelectedCarBrand] = useState("");
    const [selectedOfferLocation, setSelectedOfferLocation] = useState("");
    const {t} = useTranslation("OfferPage");

    useEffect(() => {
        apiClient.get('/carbrands').then(response => {
            setCarBrands(response.data);
        })
    }, []);
    useEffect(() => {
        const brandIndex = carBrands.findIndex(c => c === selectedCarBrand);
            OffersService.getAllOffers({
                brandIndex: brandIndex > -1 ? brandIndex : null,
                city: selectedOfferLocation
            }).then(res => {
                setOffers(res.data.value.items);
            })
    }, [selectedCarBrand, selectedOfferLocation]);

    useEffect(() => {
        OffersService.getBrandOffers(selectedOfferLocation).then(res => {
            const brandsWithLogos = res.data.value.map(item => ({
                brand: item.name,
                count: item.count,
                logo: carLogos[item.name.replace(/\s/g, '')] || null,
            }));
            setBrandOffers(brandsWithLogos);
        });
    }, []);

    useEffect(() => {
        const locationIndex = offerLocations.findIndex(c => c === selectedOfferLocation);

        OffersService.getCityOffers(locationIndex > -1 ? locationIndex : null).then(res => {
            setOfferLocations(res.data.value);
        });
    }, []);

    return (
        <Flex flexDirection={'column'} flexGrow={1}>
            <Flex
                flexDirection={{ base: "column", md: "row" }}
                alignItems={{ base: "center", md: "flex-start" }}
                justifyContent="space-between"
                w="100%"
                py={4}
            >
                <VStack
                    spacing={4}
                    alignItems={{ base: "center", md: user ? "flex-start" : "center" }}
                    flex={{ base: "1 1 auto", md: user ? "0 1 70%" : "1 1 auto" }}
                >
                    <VStack color="primary" spacing={0} alignItems={{ base: user ? "flex-start" : "center", md: user ? "flex-start" : "center" }}>
                        <Text fontWeight={600} fontSize={24} >
                            {t('BEST_OFFERS')}
                        </Text>
                        <Text fontWeight={400} fontSize={16}>
                            {t('FIND_OFFER')}
                        </Text>
                    </VStack>
                    <SelectInput bg={'white'} placeholder={t('FIND_BRAND')} contentWidth={{base: '100%', sm: '54%'}}
                         options={brandOffers}
                         onSelect={(selected) => {
                             if (!selected) {
                                 setSelectedCarBrand("");
                             } else {
                                 setSelectedCarBrand(selected.brand);
                             }
                         }}
                         optionLabelKey="brand"
                         renderOption={(item) => (
                             <Box display="flex" alignItems="center" p={2}>
                                 <Box
                                     as="img"
                                     src={item.logo}
                                     alt={item.brand}
                                     width="26px"
                                     height="26px"
                                     borderRadius="50%"
                                     mr={3}
                                 />
                                 <Text fontWeight={400} fontSize={14} lineHeight='21px' color={'#323352'}>
                                     {item.brand} <Text as="span" color={'#9495A9'}>({item.count})</Text>
                                 </Text>
                                 <Text fontSize="sm" color="gray.500">{item.region}</Text>
                             </Box>
                         )}/>
                    />
                    <SelectInput bg={'white'} placeholder={t('CHOOSE_LOCATION')} contentWidth={{base: '100%', sm: '54%'}}
                         iconOption={<HiOutlineLocationMarker/>}
                         options={offerLocations}
                         onSelect={(selectedLocation) => {
                             if (!selectedLocation) {
                                 setSelectedOfferLocation("");
                             } else {
                                 setSelectedOfferLocation(selectedLocation.name);
                             }
                         }}
                         optionLabelKey="name"
                         renderOption={(item) => (
                             <Box pl={1}>
                                 <Text fontWeight={400} fontSize={14} lineHeight='21px' color={'#323352'}>
                                     {item.name} <Text as="span" color={'#9495A9'}>({item.count})</Text>
                                 </Text>
                                 <Text fontSize="sm" color="gray.500">{item.region}</Text>
                             </Box>
                         )}/>
                </VStack>

                {user && (
                    <Box
                        order={{ base: -1, md: 1 }}
                        flex="0 0 auto"
                        ml={{ base: 0, md: 8 }}
                        mt={{ base: 4, md: 0 }}
                        w={{ base: "100%", md: "30%" }}
                        display="flex"
                        justifyContent={{ base: "center", md: "flex-end" }}
                    >
                        <FuelWidget
                            message={t('CANISTER_MESSAGE')}
                            actionText={t('USE_FUEL')}
                        />
                    </Box>
                )}
            </Flex>

            <Flex flexGrow={1} flexDirection={{base: 'column', sm: 'row'}} flexWrap={'wrap'} gap={4} justifyContent={'space-between'}>
                {offers.map((item, index) => {
                    return <OfferListItem offer={item} key={index} />
                })}
            </Flex>
        </Flex>
    )
}

export default AllOffersPage;
