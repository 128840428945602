import {
    Button,
    Icon,
    useDisclosure,
    Text,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader, ModalCloseButton, ModalBody,
} from "@chakra-ui/react";
import {useRef} from "react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
} from '@chakra-ui/react'
import {TbMenu2} from "react-icons/tb";
import {MdClose} from "react-icons/md";
import MenuLink from "./MenuLink";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../providers/AuthProvider";
import MyCanisterPage from "../../pages/MyCanisterPage/MyCanisterPage";
import {useTranslation} from "react-i18next";

const MobileMenu = ({routes}) => {
    const {t} = useTranslation("General");
    const {user, logout} = useAuth();
    const buttonRef = useRef(null);
    const navigate = useNavigate();
    const {isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose} = useDisclosure();
    const {isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose} = useDisclosure();

    const handleNavigate = (route) => {
        navigate(route);
        onDrawerClose();
    }

    const handleLogin = () => {
        navigate('/login');
        onDrawerClose();
    }

    const handleLogout = () => {
        navigate('/');
        onDrawerClose();
    }

    const handlePlan = () => {
        navigate('/plan');
        onModalOpen();
    };

    return (
        <>
            <Flex display={{base: 'flex', sm: 'none'}}>
                <Button ref={buttonRef} bg={'transparent'} onClick={onDrawerOpen} p={0}>
                    <Icon as={TbMenu2} color={'white'} boxSize={6} />
                </Button>
                <Drawer
                    isOpen={isDrawerOpen}
                    size={'xs'}
                    placement='left'
                    onClose={onDrawerClose}
                    finalFocusRef={buttonRef}
                    closeOnOverlayClick
                >
                    <DrawerOverlay/>
                    <DrawerContent bg={'primary'} color={'white'} px={0} w={'65vw !important'}>
                        <DrawerHeader display="flex" gap="8px" fontSize={16} fontWeight={400} alignItems={'center'}>
                                <Icon as={MdClose} color={'white'} boxSize={6} onClick={onDrawerClose}/>
                            {t('MENU')}
                        </DrawerHeader>

                        <DrawerBody display={'flex'} flexDirection={'column'} px={0}>
                            {routes?.filter(item => user || item.type === 'public').map((item, index) => {
                                return item.route ?
                                            (<MenuLink key={index}
                                             onClick={() => handleNavigate(item.route)}
                                             address={item.route || '#'}
                                             _hover={{ textDecoration: "none", bg: 'rgba(255, 255, 255, 0.05)' }}
                                             showActive
                                             fontSize={14}
                                             py={3} px={6}>
                                            {item.label}
                                        </MenuLink>)
                                        :
                                        (<Text onClick={onModalOpen}
                                              cursor='pointer'
                                               _hover={{ textDecoration: "none", bg: 'rgba(255, 255, 255, 0.05)' }}
                                              fontSize={14}
                                              color="white"
                                              display={'flex'}
                                              w={'100%'}
                                               py={3} px={6}>
                                            {item.icon && <Icon as={item.icon} mr={2} color={'green'} />}
                                            {item.label}
                                        </Text>)
                            })}
                        </DrawerBody>
                        <DrawerFooter display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                        {user ? (
                            <>
                                <Text opacity={0.6} fontSize={14}>{t('LOGGED_USER')}:</Text>
                                <Text fontSize={14} textOverflow="ellipsis" mb={4} fontFamily={"'Ubuntu', sans-serif"}>
                                    {user?.email}
                                </Text>
                                <Button color="white" variant="solid" width="100%" fontSize={14} fontWeight={500} bg={'secondary'} borderRadius={32} onClick={handleLogout}>
                                    {t('LOGOUT')}
                                </Button>
                            </>
                        ) : (
                                <Button color="white" variant="solid" width="100%" fontSize={14} fontWeight={500}
                                        bg={'secondary'} borderRadius={32} onClick={handleLogin}>
                                    {t('LOGIN')}
                                </Button>
                        )}
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Flex>
            <Modal isOpen={isModalOpen} onClose={onModalClose}>
                <ModalOverlay/>
                <ModalContent bg={'#FFFFFF'}  borderRadius="16px" mx={{ base: 2, md: 0 }} px={{ base: 1, md: 0 }} w={'90%'}>
                    <ModalHeader>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <MyCanisterPage onActionClick={handlePlan}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MobileMenu;
