import React, { useState, useEffect } from "react";
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from "@chakra-ui/react";
import MyOffersPage, {Type} from "./MyOffersPage/MyOffersPage";
import MyTransactionsPage from "./MyOffersPage/MyTransactionsPage";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const MyOffersTabs = () => {
    const {t} = useTranslation("OfferPage");
    const location = useLocation();
    const navigate = useNavigate();

    const getIndexFromQuery = () => {
        const params = new URLSearchParams(location.search);
        const index = parseInt(params.get("index"), 10);
        return isNaN(index) ? 0 : index;
    };

    const [activeTab, setActiveTab] = useState(getIndexFromQuery);

    useEffect(() => {
        setActiveTab(getIndexFromQuery());
    }, [location.search]);

    const handleTabChange = (index) => {
        setActiveTab(index);
        navigate(`/my?index=${index}`);
    };

    return (
        <Tabs isFitted variant="enclosed" mt={4} w={'100%'} onChange={handleTabChange} index={activeTab}>
            <TabList>
                <Tab _selected={{ color: "primary", borderBottom: "2px solid #082545" }}>
                    {t('STATUS_RESERVED')}
                </Tab>
                <Tab _selected={{ color: "primary", borderBottom: "2px solid #082545" }}>
                    {t('STATUS_PENDING')}
                </Tab>
                <Tab _selected={{ color: "primary", borderBottom: "2px solid #082545" }}>
                    {t('STATUS_REALIZED')}
                </Tab>
            </TabList>

            <TabPanels>
                <TabPanel>
                    {activeTab === 0 && <MyOffersPage />}
                </TabPanel>

                <TabPanel>
                    {activeTab === 1 && <MyTransactionsPage type={Type.PENDING} />}
                </TabPanel>

                <TabPanel>
                    {activeTab === 2 && <MyTransactionsPage type={Type.REALIZED} />}
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
}

export default MyOffersTabs;
