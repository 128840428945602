import {
    Button,
    FormControl,
    FormErrorMessage,
    Box,
    HStack,
    Input, Text,
    useNumberInput
} from "@chakra-ui/react";
import {FaMinus, FaPlus} from "react-icons/fa6";
import React from "react";

const LiterField = ({label = "", name, type= "text", errors = {}, touched = {}, onChange, step = 1, value, max, ...props}) => {
    const {getInputProps, getIncrementButtonProps, getDecrementButtonProps} = useNumberInput({
        step: step,
        defaultValue: 0,
        min: 0,
        max: max,
        value: value ?? 0,
        onChange: (valueAsString, valueAsNumber) => {
            const safeValue = valueAsString === "" ? 0 : valueAsNumber;
            onChange({ target: { name, value: safeValue } });
        }
    });

    const inc = getIncrementButtonProps();
    const dec = getDecrementButtonProps();
    const input = getInputProps();

    return (
        <FormControl isInvalid={errors[name] && touched[name]} w={'min-content'} maxW={'58vh'}>
            <HStack gap={'24px'}>
                <Button {...dec} borderRadius={'full'} bgColor={'textSecondary'} color={'white'} p={0} w={'42px'}>
                    <FaMinus size={22} />
                </Button>
                <HStack w={'min-content'} justify="center" gap={0}>
                    <Input
                        {...props}
                        {...input}
                        id={name}
                        name={name}
                        type={type}
                        variant="filled"
                        fontWeight={700} fontSize={'64px'} lineHeight={'96px'}
                        textAlign={'center'}
                        placeholder={0}
                        bg={'transparent'}
                        p={0}
                        _hover={{bg: 'transparent',border: 'none',p: 0}}
                        _focusVisible={{border: 'none', p: 0}}
                        h={'65px'}
                        w={'10vh'}
                        minW={'3ch'}
                        maxW={'30vh'}
                        overflow={'visible'}
                    />
                    <Text fontWeight={700} fontSize={'64px'} lineHeight={'96px'}>
                        {label}
                    </Text>
                </HStack>
                <Button {...inc} borderRadius={'full'} bgColor={'textSecondary'} color={'white'} aspectRatio={1} p={0} w={'42px'}>
                    <FaPlus size={22} />
                </Button>
            </HStack>
            <FormErrorMessage>{errors[name]}</FormErrorMessage>
        </FormControl>
    );
};

export default LiterField;
