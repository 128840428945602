import {Flex} from "@chakra-ui/react";
import Menu from "./components/layout/Menu";
import AllOffersPage from "./pages/AllOffersPage/AllOffersPage";
import Footer from "./components/layout/footer/Footer";
import {Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import MyOffersTabs from "./pages/MyOffersTabs/MyOffersTabs";
import PasswordReminderPage from "./pages/PasswordReminderPage/PasswordReminderPage";
import PlanPage from "./pages/PlanPage/PlanPage";
import MyPlansTabs from "./pages/MyPlansTabs/MyPlansTabs";
import ConfirmPlanPage from "./pages/PlanPage/ConfirmPlanPage/ConfirmPlanPage";
import DetailsPlanPage from "./pages/PlanPage/DetailsPlanPage/DetailsPlanPage";
import MyAccountPage from "./pages/MyAccountPage/MyAccountPage";

function App() {

    return (
            <Flex minH={'100vh'}
                  flexDirection={'column'}
                  alignItems={'center'}>
                <Menu/>
                <Flex flexGrow={1} bg={'#F1F1F1'} w={'100%'} justifyContent={'center'}>
                    <Flex w={{base: '100%', sm: '7xl'}} alignItems={'flex-start'}>
                        <Routes>
                            <Route path={"/"} element={<AllOffersPage />}/>
                            <Route path={"/plan"} element={<PlanPage />}/>
                            <Route path={"/plans"} element={<MyPlansTabs />} />
                            <Route path={"/realize"} element={<ConfirmPlanPage />} />
                            <Route path={"/details"} element={<DetailsPlanPage />}/>
                            <Route path={"/my"} element={<MyOffersTabs />}/>
                            <Route path={"/login"} element={<LoginPage />}/>
                            <Route path={"/register"} element={<RegisterPage />}/>
                            <Route path={"/reminder"} element={<PasswordReminderPage />}/>
                            <Route path={"/account"} element={<MyAccountPage />}/>
                        </Routes>
                    </Flex>
                </Flex>
                <Footer />
            </Flex>
    );
}

export default App;
