import {
    Flex, VStack,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import MyRealizedPlanList from "../../../components/comps/MyRealizedPlanList/MyRealizedPlanList";
import PlansService from "../../../api/services/PlansService";

export const Type = {
    PENDING: 'PENDING',
    REALIZED: 'REALIZED',
    PAID: 'PAID'
};

const MyRealizedPlansPage = ({type}) => {
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        PlansService.getHistoryPlans({status: type}).then(res => {
            setPlans(res.data.value);
        })
    }, []);

    return (
        <Flex flexDirection={'column'} flexGrow={1}>
            <Flex flexGrow={1} flexDirection={{base: 'column', sm: 'row'}} flexWrap={'wrap'} gap={4} justifyContent={'space-between'}>
                <VStack spacing={6} align="stretch" p={4} bg="#F0F9FD" fontFamily="Ubuntu, sans-serif" w={'full'}>
                    {plans?.map((group, index) => (
                        <MyRealizedPlanList group={group} index={index} />
                    ))}
                </VStack>
            </Flex>
        </Flex>
    )
}

export default MyRealizedPlansPage;
