import { useEffect, useState } from "react";
import {apiClient} from "../apiClient";

const CanisterStatusService = (user, interval = 5000) => {
    const [canister, setCanister] = useState({ litersUsed: user?.canister?.litersUsed ?? 0, litersAvailable: user?.canister?.litersAvailable ?? 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchCanisterStatus = async () => {
            try {
                if(user?.id) {
                    const response = await apiClient.get(`/canister/${user?.id}`);
                    if (isMounted) {
                        setCanister(response.data.value);
                        if(canister) {
                            user.canister = response.data.value;
                            localStorage.setItem("user", JSON.stringify(user));
                        }
                        setLoading(false);
                    }
                }
            } catch (err) {
                if (isMounted) {
                    setError("Błąd podczas pobierania danych.");
                    setLoading(false);
                }
            }
        };

        fetchCanisterStatus();
        const intervalId = setInterval(fetchCanisterStatus, interval);

        return () => {
            isMounted = false;
            clearInterval(intervalId);
        };
    }, [user, interval]);

    return { canister, loading, error };
};

export default CanisterStatusService;
