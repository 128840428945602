import MyCanister from "./MyCanister/MyCanister";
import {Box, Button} from "@chakra-ui/react";
import {useAuth} from "../../providers/AuthProvider";
import {useTranslation} from "react-i18next";

const MyCanisterPage = ({ onActionClick, actionText }) => {
    const {t} = useTranslation("General");
    const {user} = useAuth();

    return (
        <Box w={'100%'} display="flex" flexDirection={"column"} alignItems="center" gap={'16px'}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                bg="#FFFFFF"
                color="#323352"
                fontFamily={'Poppins'}
                fontWeight={700}
                borderRadius="16px"
                p="24px"
                w='100%'
            >
                <Box w={'100%'} display="flex" flexDirection={"column"} alignItems="center" gap={'16px'}>
                    <MyCanister volume={user?.canister.litersAvailable || 0}/>
                    <Button
                        bg="#2FEA52"
                        color="#323352"
                        borderRadius="full"
                        onClick={onActionClick}
                        fontFamily={'Ubuntu'}
                        fontSize="16px"
                        lineHeight="24px"
                        px={{ base: '28px', md: '36px' }}
                        disabled={user?.canister.litersAvailable == 0}
                    >
                        {actionText || t('PLAN')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default MyCanisterPage;
