import React, { useState, useEffect } from "react";
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Flex,
    Text,
} from "@chakra-ui/react";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import MyPlansPage from "./MyPlansPage/MyPlansPage";
import MyRealizedPlansPage from "./MyRealizedPlansPage/MyRealizedPlansPage";
import {useTranslation} from "react-i18next";

export const Type = {
    PENDING: 'PENDING',
    REALIZED: 'REALIZED',
    PAID: 'PAID'
};

const MyPlansTabs = () => {
    const {t} = useTranslation("PlanPage");
    const location = useLocation();
    const navigate = useNavigate();

    const getIndexFromQuery = () => {
        const params = new URLSearchParams(location.search);
        const index = parseInt(params.get("index"), 10);
        return isNaN(index) ? 0 : index;
    };

    const [activeTab, setActiveTab] = useState(getIndexFromQuery);

    useEffect(() => {
        setActiveTab(getIndexFromQuery());
    }, [location.search]);

    const handleTabChange = (index) => {
        setActiveTab(index);
        navigate(`/plans?index=${index}`);
    };

    return (
        <Flex flexDirection={'column'} w={'full'} justifyContent={'center'} alignItems={'center'}
              gap={0} p={0} m={0}>
            <Tabs isFitted variant="enclosed" mt={4} w={'100%'} onChange={handleTabChange} index={activeTab}>
                <Text bg={'#FFFFFF'} w={'full'} textAlign={"center"} py={'10px'} px={'16px'}
                fontFamily={'Poppins'} fontWeight={500} fontSize={16} lineHeight={'25px'} color={'#323352'}>
                    {t('TABS_TITLE')}
                </Text>
                <TabList bg={'#FFFFFF'}>
                    <Tab _selected={{ color: "primary", borderBottom: "2px solid #082545" }}>
                        {t('STATUS_PLANED')}
                    </Tab>
                    <Tab _selected={{ color: "primary", borderBottom: "2px solid #082545" }}>
                        {t('STATUS_REALIZED')}
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel p={{base: 0, sm: 4}}>
                        {activeTab === 0 && <MyPlansPage type={Type.PENDING} />}
                    </TabPanel>

                    <TabPanel p={{base: 0, sm: 4}}>
                        {activeTab === 1 && <MyRealizedPlansPage type={Type.REALIZED} />}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    );
}

export default MyPlansTabs;
