import React, { useState } from "react";
import {
    Text,
    Flex,
    Link,
    Input,
    Button,
    Stack
} from "@chakra-ui/react";

const EditableField = ({ label, value, name, onSubmit, labels, clearOnEdit = false, readonly = false }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);
    const [tempValue, setTempValue] = useState(value);

    const handleSave = () => {
        setFieldValue(tempValue);
        setIsEditing(false);
        if(clearOnEdit) {
            setFieldValue(value);
        }
        onSubmit(name, tempValue);
    };

    const handleCancel = () => {
        setTempValue(fieldValue);
        setIsEditing(false);
    };

    return (
        <Flex justifyContent="space-between" w="100%">
            <Text color="#9495A9" w="25%">
                {label}
            </Text>
            {isEditing ? (
                <Flex flexDirection="row" w={"full"} >
                    <Input
                        value={tempValue}
                        onChange={(e) => setTempValue(e.target.value)}
                        autoFocus
                        fontSize="16px"
                        fontWeight="500"
                        color="#323352"
                        bg="#FFFFFF"
                        w="55%"
                        mr={'40%'}
                    />
                    <Stack direction="row" spacing={0} mt={2} w={"full"} justifyContent="flex-end" alignItems="center">
                        <Button bg="green" size="sm" onClick={handleSave} px={6}>
                            {labels.save ?? 'Zapisz'}
                        </Button>
                        <Link
                            color="#2A1E8A"
                            fontWeight="500"
                            fontSize="14px"
                            w="20%"
                            textAlign="right"
                            onClick={handleCancel}
                        >
                            {labels.cancel ?? 'Anuluj'}
                        </Link>
                    </Stack>
                </Flex>
            ) : (
                <Text
                    fontWeight="700"
                    color="#323352"
                    w="55%"
                >
                    {fieldValue}
                </Text>
            )}
            {(!isEditing) && (
                <Link
                    color="#2A1E8A"
                    fontWeight="500"
                    fontSize="14px"
                    w="20%"
                    textAlign="right"
                    cursor={readonly ? "unset" : "pointer"}
                    onClick={() => {
                        if(!readonly) {
                            setIsEditing(true);
                            if (clearOnEdit) {
                                setTempValue('');
                            }
                        }
                    }}
                >
                    {!readonly && (
                        labels?.edit ?? 'Edytuj'
                    )}
                </Link>
            )}
        </Flex>
    );
};

export default EditableField;
