import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Text,
    Stack,
    Image, Flex, Input, Checkbox
} from '@chakra-ui/react';
import {useLocation, useNavigate} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {AiOutlineCamera} from "react-icons/ai";
import PlansService from "../../../api/services/PlansService";
import utils from "../../../components/helpers/FormatHelpers";
import * as Yup from "yup";
import TextInput from "../../../components/textInput/TextInput";
import {FileUploader} from "react-drag-drop-files";
import {useMemo, useState} from "react";
import {BASE_URL} from "../../../config";
import placeholder from "../../../assets/images/placeholder_image.png"
import SelectInput from "../../../components/comps/SelectInput";

const fileTypes = ["JPG", "JPEG", "PNG"];

const ConfirmPlanPage = () => {
    const {t} = useTranslation("PlanPage");
    const location = useLocation();
    const navigate = useNavigate();
    const plan = location.state;
    const [image, setImage] = useState();
    const [station, setStation] = useState([]);

    const stationOptions = [
        "Orlen", "BP", "Moya", "MOL", "Shell", "Circle K", "Amic Energy", "Lukoil",
        "Huzar", "Avia", "Neste", "IDS", "AS 24", "Lotos", "Bliska", "Statoil",
        "Total", "Q8", "Texaco", "Jet", "Esso", "Aral", "Gulf", "Star", "Tamoil",
        "Eni", "Agip", "OMV", "Rompetrol", "E.Leclerc", "Auchan", "Carrefour",
        "Intermarché", "Tesco", "Makro", "MZK", "PKS", "PKP", "Orbis"
    ];

    const handleImageChange = (file) => {
        setImage(file);
    };

    const imagePreview = useMemo(() => {
        if (plan?.imageUri) {
            setImage(plan.imageUri);
        }
        if (!image) {
            return '';
        }
        return ( image instanceof Blob ) ? URL.createObjectURL(image) : `${BASE_URL}/${image}`;
    }, [image, plan]);

    const handleSubmit = (values, {setSubmitting}) => {
        PlansService.realize({
            id: plan?.id,
            station: station ?? values?.station,
            amount: values?.amount
        }, image).then(response => {
            if (response.data.isSuccess) {
                setTimeout(() => {
                    handleNavigate('/plans?index=1');
                }, 2000);
            }
        })
    }
    const handleNavigate = (route) => {
        navigate(route);
    }
    const initialValues = {
        station: '',
        amount: 0,
        consent: false,
        file: null
    };

    const validationSchema = Yup.object({
        station: Yup.string()
            .required(t('STATION_REQUIRED')),
        amount: Yup.number()
            .typeError(t('AMOUNT_MUST_BE_NUMBER'))
            .positive(t('AMOUNT_MUST_BE_POSITIVE'))
            .test(
                'is-decimal',
                t('AMOUNT_MUST_BE_DECIMAL'),
                value => (value + "").match(/^\d+(\.\d{1,2})?$/)
            )
            .required(t('AMOUNT_REQUIRED')),
        consent: Yup.boolean()
            .oneOf([true], t('CONSENT_REQUIRED')),
        file: Yup.mixed()
            .required(t('FILE_REQUIRED'))
    });

    return (
        <Flex w={'full'} justifyContent={'center'}>
            <Box
                bg={'#F0F9FD'}
                minW={{base: '100%', sm: 'lg'}}
                m={0}
                mt={{base: 0, sm: 2}}>

                <Formik
                    initialValues={initialValues}
                    isInitialValid={false}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({isSubmitting, errors, touched, setFieldValue, isValid, dirty}) => (
                        <Form>
                            <Stack spacing={4} align="center" mb={8} w={"full"}>
                                <Text
                                    textAlign="center"
                                    lineHeight={{base: '24px', sm: 'sm'}}
                                    fontSize={{base: 16, sm: 'lg'}}
                                    fontFamily={'Poppins'}
                                    fontWeight={600}
                                    color="#323352"
                                    bg={'white'}
                                    width={'100%'}
                                    py='10px'
                                    px='16px'
                                >
                                    {t('CONFIRM_TITLE')}
                                </Text>
                                <Flex w={'full'} justifyContent={'center'} alignItems={"center"} flexDirection={'column'} px={{base: 4, sm: 6}}>

                                    <Flex justifyContent="space-between" mb={4} w={'full'}>
                                        <Box>
                                            <Text fontSize="12px" color="#9495A9">
                                                {t('QUANTITY')}
                                            </Text>
                                            <Text fontSize="18px" fontWeight="700" color="#323352">
                                                {plan?.litersUsed} l
                                            </Text>
                                        </Box>
                                        <Box textAlign="right">
                                            <Text fontSize="12px" color="#9495A9">
                                                {t('SCHEDULED')}
                                            </Text>
                                            <Text fontSize="18px" fontWeight="700" color="#323352">
                                                {utils.dateFormat(plan?.createdAtUtc)}
                                            </Text>
                                        </Box>
                                    </Flex>
                                    <Box mb={4} w={'full'}>
                                        <Text fontSize="12px" color="#9495A9" mb={1}>
                                            {t('STATION')}
                                        </Text>
                                        <SelectInput
                                            name="station"
                                            fontWeight={500}
                                            bg="white"
                                            borderRadius="12px"
                                            options={stationOptions}
                                            placeholder={t("CHOOSE_STATION_ADDRESS")}
                                            onSelect={(station) => {
                                                if (!station) {
                                                    setStation("");
                                                    setFieldValue("station", "");
                                                } else {
                                                    setStation(station);
                                                    setFieldValue("station", station);
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box mb={4} w={'full'}>
                                        <Text fontSize="12px" color="#9495A9" mb={1}>
                                            {t('TRANSACTION_AMOUNT')}
                                        </Text>
                                        <TextInput
                                            name="amount"
                                            type="number"
                                            fontWeight={500}
                                            bg="white"
                                            borderRadius="12px"
                                            placeholder={t("TRANSACTION_AMOUNT")}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Box>
                                    <Box mb={4} w={'full'}>
                                        <Text fontSize="12px" color="#9495A9" mb={1}>
                                            {t('RECEIPT_PHOTO')}
                                        </Text>

                                        <FileUploader
                                            handleChange={(file) => {
                                                handleImageChange(file);
                                                setFieldValue("file", file);
                                            }}
                                            name="file" types={fileTypes}>
                                            <Box display={'flex'} p={4} bg={'white'} flexDirection={'row'}
                                                 borderRadius="12px"
                                                 border="1px dashed #D3D3D3">
                                                {imagePreview && (
                                                    <Image fallbackSrc={placeholder}
                                                           bg={'separator'}
                                                           src={imagePreview}
                                                           w={'6rem'} aspectRatio={16 / 9} fit={'cover'} borderRadius={6}/>
                                                )}
                                                {!imagePreview && (
                                                    <AiOutlineCamera size={24} color="#9495A9" />
                                                )}
                                                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
                                                     textAlign={'left'}
                                                     justifyContent={'center'}>
                                                    <Text ml={2} fontSize="12px" color="#9495A9">
                                                        {t('UPLOAD_PHOTO')}
                                                        <br />
                                                        {t('PHOTO_MAX')}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </FileUploader>
                                    </Box>
                                    <Field name="consent">
                                        {({field, form}) => (
                                            <Checkbox name="consent" alignSelf={'flex-start'} errors={form.errors}
                                                      touched={form.touched}
                                                      onChange={field.onChange}
                                                      isChecked={field.value}
                                                      iconColor="#00C853"
                                                      borderColor="#E3E3E3"
                                                      _checked={{
                                                          borderColor: '#00C853',
                                                          color: '#00C853'
                                                      }}
                                                      _hover={{
                                                          borderColor: '#888'
                                                      }}
                                                      sx={{
                                                          'span.chakra-checkbox__control': {
                                                              backgroundColor: 'white',
                                                              borderRadius: '4px',
                                                              borderColor: '#E3E3E3',
                                                              borderWidth: '1px',
                                                              width: '20px',
                                                              height: '20px',
                                                          }
                                                      }}>
                                                <Text fontSize="12px" color="#323352">
                                                    {t('CONSENT')}
                                                </Text>
                                            </Checkbox>
                                        )}
                                    </Field>
                                </Flex>
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'center'} w={'full'} mt={8} mb={4} gap={'8px'} px={'16px'}>
                                    <Button border={'2px'} borderColor={'#2FEA52'} borderRadius={'40px'} w={'42px'} p={0}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                            <path d="M8 15L1 8M1 8L8 0.999999M1 8L19 8" stroke="#011938" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </Button>
                                    <Button
                                        display={"flex"}
                                        type="submit"
                                        justifySelf="center"
                                        bg="green"
                                        px={24}
                                        borderRadius={40}
                                        fontWeight={700}
                                        fontSize={{base: 16, sm: 'md'}}
                                        lineHeight={'18.38px'}
                                        color="primary"
                                        h={'42px'}
                                        w={'full'}
                                        isDisabled={isSubmitting || !isValid || !dirty}
                                    >
                                        <Text pr={'10px'}>
                                            {t('REGISTER')}
                                        </Text>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                            <path d="M12 1L19 8M19 8L12 15M19 8H1" stroke="#011938" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </Button>
                                </Box>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Flex>
    )
}

export default ConfirmPlanPage;
