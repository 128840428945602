import {Box, Button, Flex, HStack, Image, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter} from "@chakra-ui/react";
import shopMockup from "../../../assets/images/shop_mockup.png";
import price from "../../../assets/images/price.svg";
import {useEffect, useMemo, useState} from "react";
import {FiClock} from "react-icons/fi";
import CanisterIcon from "../../../assets/icons/CanisterIcon";
import {BASE_URL} from "../../../config";
import carLogos from "../../../assets/images/carbrands";
import moment from "moment";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {useAuth} from "../../../providers/AuthProvider";
import {useNavigate} from "react-router-dom";
import OffersService from "../../../api/services/OffersService";
import {useTranslation} from "react-i18next";
import info from "../../../assets/images/i.svg";

const OfferListItem = ({offer}) => {
    const {t} = useTranslation("OfferPage");
    const {user} = useAuth();
    const {isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose} = useDisclosure();
    const {isOpen: isInfoModalOpen, onOpen: onInfoModalOpen, onClose: onInfoModalClose} = useDisclosure();
    const [isHovering, setHovering] = useState(false);
    const navigate = useNavigate();

    const brandLogo = useMemo(() => {
        return carLogos[offer?.client?.carBrand];
    }, [offer]);

    const validUntilFurtherNotice = t('VALID_UNTIL_FURTHER_NOTICE');

    const expirationTime = useMemo(() => {
        return offer?.noExpirationDate ? validUntilFurtherNotice : moment(offer?.expirationDateUtc).format('DD.MM.YYYY');
    }, [offer]);

    const handleMouseEnter = () => {
        setHovering(true);
    };

    const handleMouseLeave = () => {
        setHovering(false);
    };

    const handleNavigate = (route) => {
        navigate(route);
    };

    const handleLogin = () => {
        handleNavigate('/login');
        onModalOpen();
    };

    const handleSetting = () => {
        handleNavigate('/account');
    }

    const handleReserve = () => {
        if(user) {
            OffersService.getReservedOffers({}).then(res => {
                if(res.data?.value?.length > 0 && (!user.accountName || !user.accountNumber || !user.address)) {
                    onModalClose();
                    onInfoModalOpen();
                }else{
                    OffersService.reserve(offer?.id).then(response => {
                        if (response.data.isSuccess) {
                            setTimeout(() => {
                                handleNavigate('/my');
                            }, 2000);
                            onModalClose();
                        }
                    });
                }
            })
        }
    }

    const OfferDetails = ({ minimalPrice, litersAward }) => (
        <HStack w={'100%'} justifyContent={'center'} alignItems={'center'} spacing={0} mb={5}>
            <Flex borderRadius={'full'} borderColor={'primary'} flex={1} py={1} alignItems={'center'}
                  justifyContent={'center'} fontWeight={500} fontSize={16}>
                <Box h={'2px'} bg={'primary'} w={'100%'}/>
            </Flex>
            <Flex flex={{ base: 0.6, sm: 1 }} alignItems={'center'} justifyContent={'center'}>

                <Flex borderWidth={2} borderRadius={'full'} borderColor={'primary'} flex={1} py={1} columnGap={1} alignItems={'center'}
                      justifyContent={'center'} fontWeight={500} fontSize={16} w={'100%'}>
                    <CanisterIcon boxSize={3}/>
                    {litersAward} {t('LITERS')}
                </Flex>

            </Flex>
            <Flex borderRadius={'full'} borderColor={'primary'} flex={1} py={1} columnGap={1} alignItems={'center'}
                  justifyContent={'center'} fontWeight={500} fontSize={16}>
                <Box h={'2px'} bg={'primary'} w={'100%'}/>
            </Flex>
        </HStack>
    );

    return (
        <>
            <Flex bg={'white'} p={4} borderRadius="12px" w={{base: '100%', sm: '32%'}} justifyContent={'center'}
                flexDirection={'column'} alignItems={'center'} style={{
                position: 'relative',
                top: isHovering ? -6 : '0px',
                transition: 'top ease 0.5s',
                borderBottom: '1px solid var(--Text-secondary, #9495A9)'
            }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} rowGap={3}>
                <Flex position={'relative'} w={'100%'} justifyContent={'center'}>
                    <Box position={'absolute'} left={0} top={1} bg={"white"} borderRadius={4} p={1} w={'40px'} h={'40px'}>
                        <Image src={shopMockup} objectFit={'contain'}/>
                    </Box>
                    <Box position={'absolute'} right={0} top={1} bg={"white"} borderRadius={4} p={1} w={'40px'} h={'40px'}>
                        <Image src={brandLogo} objectFit={'contain'}/>
                    </Box>
                    <Image src={`${BASE_URL}/${offer.imageUri}`} w={'90%'} aspectRatio={16/9} objectFit={'contain'} borderRadius={6} />
                </Flex>

                <OfferDetails minimalPrice={offer.minimalPrice} litersAward={offer.litersAward} />

                <Flex color={'primary'} flexDirection={'column'} alignItems={'center'} py={2}>
                    <Text fontWeight={700} fontSize={14}>{offer.name}</Text>
                    <Text color={'primary'} fontWeight={400} fontSize={13}>{offer.client.companyName}</Text>
                </Flex>

                <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap="12px">
                    <Button color="primary" variant="solid" _hover={{bg: 'green.500'}} px={14} fontSize={16}
                            fontWeight={700} bg={'green'} borderRadius={32} onClick={onModalOpen}>
                        {t('DISPLAY_DETAILS')}
                    </Button>
                    <Flex alignItems={'center'} gap={1} color={'gray.400'} fontWeight={400} fontSize={13}>
                        <FiClock/>
                        {t('VALID_DATE_TO')} {expirationTime}
                    </Flex>
                </Flex>
            </Flex>

            <Modal isOpen={isModalOpen} onClose={onModalClose} size="md">
                <ModalOverlay />
                <ModalContent borderRadius="16px" mx={{ base: 2, md: 0 }} px={{ base: 1, md: 0 }}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex position={'relative'} w={'100%'} justifyContent={'center'}>
                            <Box position={'absolute'} left={0} top={1} bg={"white"} borderRadius={4} p={1} w={'40px'} h={'40px'}>
                                <Image src={shopMockup} objectFit={'contain'}/>
                            </Box>
                            <Box position={'absolute'} right={0} top={1} bg={"white"} borderRadius={4} p={1} w={'40px'} h={'40px'}>
                                <Image src={brandLogo} objectFit={'contain'}/>
                            </Box>
                            <Image src={`${BASE_URL}/${offer.imageUri}`} w={'90%'} aspectRatio={16/9} objectFit={'contain'} borderRadius={6} mb={1}/>
                        </Flex>
                        <OfferDetails minimalPrice={offer.minimalPrice} litersAward={offer.litersAward} />
                        <Flex color={'primary'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Text fontWeight={400} fontSize={13} color="#9495A9" pb={'24px'}>
                                {t('OFFER_INFO', {liters: offer.litersAward})}
                            </Text>
                        </Flex>
                        <Flex color={'primary'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Text fontWeight={700} fontSize={14}>{offer.name}</Text>
                            <Text color={'primary'} fontWeight={400} fontSize={13}>{offer.client.companyName}</Text>
                            <Flex color="#9495A9" flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Flex gap="6px">
                                    <HiOutlineLocationMarker/>
                                    <Text fontWeight={400} fontSize={13}>
                                        {offer.client.address?.street ?? ''},  {offer.client.address?.postalCode ?? ''}  {offer.client.address?.city}
                                    </Text>
                                </Flex>
                                <Flex gap="6px" alignItems={'center'} justifyContent={'center'} pt="24px">
                                    <Text fontWeight={400} fontSize={15} align={"center"} lineHeight="24px">
                                        {offer.description}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter display="flex" flexDirection="column" alignItems="center" w="100%">
                        <Flex fontSize={13} color="#9495A9" mb={2} alignItems={'center'} gap={1}>
                            <Image src={price}/> {t('OFFER_PRICE')}: {offer.minimalPrice?.toLocaleString('pl-PL')} {t('PLN')}
                        </Flex>
                        <Flex fontSize={13} color="#9495A9" mb={4} alignItems={'center'} gap={1}>
                            <FiClock /> {t('VALID_DATE_TO')} {expirationTime}
                        </Flex>
                        {user ? (
                            <Button color="primary" variant="solid" _hover={{bg: 'green.500'}} py={6} px={14} fontSize={16} fontWeight={700} mt={3}
                                    bg={'green'} borderRadius={32} w={'100%'} onClick={handleReserve}>
                                {t('RESERVE')}
                            </Button>
                        ) : (
                            <Button color="primary" variant="solid" _hover={{bg: 'green.500'}} py={6} px={14} fontSize={16} fontWeight={700} mt={3}
                                    bg={'green'} borderRadius={32} w={'100%'} onClick={handleLogin}>
                                {t('LOGIN_TO_RESERVE')}
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isInfoModalOpen} onClose={onInfoModalClose} size="sm">
                <ModalOverlay />
                <ModalContent borderRadius="16px" mx={{ base: 2, md: 0 }} px={{ base: 1, md: 0 }}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box w={'100%'} display="flex" flexDirection={"column"} alignItems="center" gap={'16px'}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                bg="#FFFFFF"
                                color="#323352"
                                fontFamily={'Poppins'}
                                fontWeight={700}
                                borderRadius="16px"
                                p="16px"
                                w='100%'
                            >
                                <Box w={'100%'} display="flex" flexDirection={"column"} alignItems="center" gap={'16px'}>
                                    <Flex direction="column" alignItems="center" justifyContent="center" textAlign="center">
                                        <Image src={info} alt="Info" w={'100px'} pb={'16px'} />
                                        <Text fontSize="14px" fontWeight="500" lineHeight={'21px'}>
                                            {t('RESERVED_INFO')}
                                        </Text>
                                    </Flex>
                                </Box>
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter display="flex" flexDirection="column" alignItems="center" w="100%">
                        <Button color="primary" variant="solid" _hover={{bg: 'green.500'}} py={6} px={14} fontSize={16} fontWeight={700} mt={3}
                                bg={'green'} borderRadius={32} w={'100%'} onClick={handleSetting}>
                            {t('GO_TO_SETTING')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default OfferListItem;
