import {Flex} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import MyPlanListItem from "../../../components/comps/MyPlanListItem/MyPlanListItem";
import PlansService from "../../../api/services/PlansService";

export const Type = {
    PENDING: 'PENDING',
    REALIZED: 'REALIZED',
    PAID: 'PAID'
};

const MyPlansPage = ({type}) => {
    const [plans, setPlans] = useState([]);

    useEffect(() => {

        PlansService.getPlans({status: type}).then(res => {
            setPlans(res.data.value.items);
        })
    }, []);

    return (
        <Flex flexDirection={'column'} flexGrow={1}>
            <Flex flexGrow={1} flexDirection={{base: 'column', sm: 'row'}} flexWrap={'wrap'} gap={4} justifyContent={'space-between'}>
                {plans.map((item, index) => {
                    return <MyPlanListItem item={item} key={index} />
                })}
            </Flex>
        </Flex>
    )
}

export default MyPlansPage;
