import {
    Flex,
    Box,
    Icon,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton, ModalBody, Text
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import MenuLink from "./MenuLink";
import {Fragment} from "react";
import MyCanisterPage from "../../pages/MyCanisterPage/MyCanisterPage";

const WebMenu = ({routes}) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleNavigate = (route) => {
        navigate(route);
    };

    const handlePlan = () => {
        navigate('/plan', {state: {url: window.location.pathname}});
        onClose();
    };

    return (
        <>
            <Flex py={4} justifyContent="center"
                  display={{base: 'none', sm: 'flex'}}>
                <Flex w="7xl" alignItems="center">
                    <Box w="1px" h="100%" bg="secondary" />
                    {routes.map((item, index) => (
                        <Fragment key={index}>
                            {index !== 0 && (
                                <Box w="1px" h="100%" bg="secondary" />
                            )}
                            <Flex flex="1" alignItems="center" justifyContent="center">
                                {item.route ?
                                    (
                                        <MenuLink
                                            onClick={() => handleNavigate(item.route)}
                                            address={item.route || '#'}
                                            _hover={{textDecoration: "none", bg: 'rgba(255, 255, 255, 0.05)', color: 'green'}}
                                            fontSize={14}
                                            color="white"
                                            showActive
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            textAlign="center"
                                            w={'100%'}
                                            py={2}
                                        >
                                            {item.icon && <Icon as={item.icon} mr={2} color={'green'} />}
                                            {item.label}
                                        </MenuLink>
                                    ) : (
                                        <Text onClick={onOpen}
                                            cursor='pointer'
                                            _hover={{textDecoration: "none", bg: 'rgba(255, 255, 255, 0.05)', color: 'green'}}
                                            fontSize={14}
                                            color="white"
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            textAlign="center"
                                            w={'100%'}
                                            py={2}>
                                            {item.icon && <Icon as={item.icon} mr={2} color={'green'} />}
                                            {item.label}
                                        </Text>
                                    )
                                }
                            </Flex>
                        </Fragment>
                    ))}
                    <Box w="1px" h="100%" bg="secondary" />
                </Flex>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#FFFFFF'} borderRadius="16px" mx={{ base: 2, md: 0 }} px={{ base: 1, md: 0 }}>
                    <ModalHeader>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <MyCanisterPage onActionClick={handlePlan}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default WebMenu;
