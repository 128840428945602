import {apiClient} from "../apiClient";
import QueryHelpers from "../../components/helpers/QueryHelpers";

const getAllOffers = (values) => {
    const params = {
        pageSize: 30,
        page: 1,
        sortOrder: "desc",
        carBrand: values?.brandIndex,
        brand: values?.brand,
        city: values?.city,
        withoutReserved: values?.withoutReserved
    }

    const query = QueryHelpers.buildQueryString(params);
    return apiClient.get(`/offers?${query}`);
}

const reserve = (offerId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const form = new FormData();
    form.append('offerId', offerId);
    form.append('userId', user.id);

    return apiClient.post('/offers/reserve', form, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${user.bearerToken}`
        }
    });
}

const getReservedOffers = (values) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const form = new FormData();
    form.append('carBrand', values?.carBrand ?? "");
    form.append('city', values?.city ?? "");
    form.append('userId', user.id);

    return apiClient.post('/offers/reserved', form, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${user.bearerToken}`
        }
    });
}

const getCityOffers = (carBrand) => {
    const params = {
        pageSize: 100,
        page: 1,
        sortOrder: "desc",
        carBrand: carBrand
    }

    const query = QueryHelpers.buildQueryString(params);
    return apiClient.get(`/offers/cities?${query}`);
}

const getBrandOffers = (city) => {
    const params = {
        pageSize: 100,
        page: 1,
        sortOrder: "desc",
        city: city
    }

    const query = QueryHelpers.buildQueryString(params);
    return apiClient.get(`/offers/carbrands?${query}`);
}

export default {
    getAllOffers,
    getReservedOffers,
    reserve,
    getCityOffers,
    getBrandOffers
}
