import {createContext, useContext, useEffect, useState} from "react";
import {apiClient} from "../client/apiClient";
import {useNavigate} from "react-router-dom";
import useCanisterStatus from "./../api/services/CanisterStatusService";
import {useTranslation} from "react-i18next";

export const AuthContext = createContext();
const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const navigate = useNavigate();
    const { canister, loading, error } = useCanisterStatus(user);
    const {i18n} = useTranslation();

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));
    }, []);

    const login = (email, password) => {
        apiClient.post("/users/login", {email, password}).then(response => {
            if(response.data.isSuccess) {
                localStorage.setItem("user", JSON.stringify(response.data.value));
                setUser(response.data.value);
                navigate("/");
            } else {
                setLoginError(response.data.error?.message || "Unknown error occurred.");
            }
        })
        .catch(error => {
            setLoginError(error.response?.data?.error?.message || "Network error occurred.");
        })
    }

    const logout = () => {
        localStorage.removeItem("user");
        setUser(null);
        navigate("/");
    }

    const register = (values) => {
        const body = {
            email: values.email,
            registrationNumber: values.registrationNumber,
            password: values.password,
            phone: values.phone,
            name: values.name,
            language: values.language ?? i18n.language
        }

        return apiClient.post('/users/register', body).then(res => {
            navigate("/login");
        })
    }

    const update = (values) => {
        const body = {
            id: user.id,
            name: values.name ?? user.name,
            email: values.email ?? user.email,
            phone: values.phone ?? user.phone,
            registrationNumber: values.registrationNumber ?? user.registrationNumber,
            birthDate: (values.birthDate ? new Date(values.birthDate).toISOString() : user.birthDate),
            password: values.password ?? user.password,
            accountName: values.accountName ?? user.accountName ?? '',
            accountNumber: values.accountNumber ?? user.accountNumber ?? '',
            address: values.address ?? user.address ?? '',
            language: values.language ?? user.language ?? i18n.language
        }
        const config = {
            headers: {
                'Authorization': `Bearer ${user.bearerToken}`
            }
        };
        return apiClient.post('/users/update', body, config).then(res => {
            if(res.data.isSuccess) {
                const updatedUser = res.data.value;
                user.companyName = updatedUser.companyName;
                user.email = updatedUser.email;
                user.phone = updatedUser.phone;
                user.nip = updatedUser.nip;
                user.carBrand = updatedUser.carBrand;
                user.city = updatedUser.city || '';
                user.street = updatedUser.address || '';
                user.postalCode = updatedUser.postalCode || '';
                user.avatar = updatedUser.avatar;
                user.accountName = updatedUser.accountName;
                user.accountNumber = updatedUser.accountNumber;
                user.address = updatedUser.address;
                user.language = updatedUser.language;
                localStorage.setItem("user", JSON.stringify(user));
                setUser(user);
            } else {
                setLoginError(res.data.error?.message || "Unknown error occurred.");
            }
        })
    }

    const remind = (values) => {
        const body = {
            email: values.email
        }

        return apiClient.post('/users/remind', body).then(res => {
            navigate("/login");
        })
    }

    const remove = () => {
        const body = {
            id: user.id
        }
        const config = {
            headers: {
                'Authorization': `Bearer ${user.bearerToken}`
            }
        };
        return apiClient.post('/users/remove', body, config).then(res => {

        })
    }

    return (
        <AuthContext.Provider
            value={{user, login, logout, register, update, remind, loginError, remove}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext);
