import {
    Box, Text,
    Flex, VStack,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import OffersService from "../../../api/services/OffersService";
import SelectInput from "../../../components/comps/SelectInput";
import {useAuth} from "../../../providers/AuthProvider";
import {HiOutlineLocationMarker} from "react-icons/hi";
import carLogos from "../../../assets/images/carbrands";
import MyOfferListItem from "../../../components/comps/MyOfferListItem/MyOfferListItem";
import {useTranslation} from "react-i18next";

export const Type = {
    PENDING: 'PENDING',
    REALIZED: 'REALIZED',
    PAID: 'PAID'
};

const MyOffersPage = () => {
    const {t} = useTranslation("General");
    const {user} = useAuth();
    const [offers, setOffers] = useState([]);
    const [carBrands, setCarBrands] = useState([]);
    const [offerLocations, setOfferLocations] = useState([]);
    const [selectedCarBrand, setSelectedCarBrand] = useState("");
    const [selectedOfferLocation, setSelectedOfferLocation] = useState("");

    useEffect(() => {
        OffersService.getBrandOffers(selectedOfferLocation).then(res => {
            const brandsWithLogos = res.data.value.map(item => ({
                brand: item.name,
                count: item.count,
                logo: carLogos[item.name.replace(/\s/g, '')] || null,
            }));
            setCarBrands(brandsWithLogos);
        });
    }, []);

      useEffect(() => {
        if(user) {
              const brandIndex = carBrands.findIndex(c => c.brand === selectedCarBrand);
              OffersService.getReservedOffers({
                  carBrand: brandIndex > -1 ? selectedCarBrand : "",
                  city: selectedOfferLocation
              }).then(res => {
                  setOffers(res.data.value);
              })
        }
    }, [carBrands, user, selectedCarBrand, selectedOfferLocation]);

    useEffect(() => {
        const locationIndex = offerLocations.findIndex(c => c === selectedOfferLocation);

        OffersService.getCityOffers(locationIndex > -1 ? locationIndex : null).then(res => {
            setOfferLocations(res.data.value);
        });
    }, []);

    return (
        <Flex flexDirection={'column'} flexGrow={1}>
            <Flex
                flexDirection={{ base: "column", md: "row" }}
                alignItems={{ base: "center", md: "flex-start" }}
                justifyContent="space-between"
                w="100%"
                py={4}
            >
                <VStack
                    spacing={4}
                    alignItems="center"
                    flex="1 1 auto"
                >
                    <SelectInput bg={'white'} placeholder={t('PLACEHOLDER_SEARCH_BRAND')} contentWidth={{base: '100%', sm: '54%'}}
                                 options={carBrands}
                                 onSelect={(selected) => {
                                     setSelectedCarBrand(selected.brand);
                                 }}
                                 optionLabelKey="brand"
                                 renderOption={(item) => (
                                     <Box display="flex" alignItems="center" p={2}>
                                         <Box
                                             as="img"
                                             src={item.logo}
                                             alt={item.brand}
                                             width="26px"
                                             height="26px"
                                             borderRadius="50%"
                                             mr={3}
                                         />
                                         <Text fontWeight={400} fontSize={14} lineHeight='21px' color={'#323352'}>
                                             {item.brand} <Text as="span" color={'#9495A9'}>({item.count})</Text>
                                         </Text>
                                         <Text fontSize="sm" color="gray.500">{item.region}</Text>
                                     </Box>
                                 )}/>
                    />
                    <SelectInput bg={'white'} placeholder={t('PLACEHOLDER_SEARCH_LOCATION')} contentWidth={{base: '100%', sm: '54%'}}
                                 iconOption={<HiOutlineLocationMarker/>}
                                 options={offerLocations}
                                 onSelect={(selectedLocation) => {
                                     setSelectedOfferLocation(selectedLocation.city);
                                 }}
                                 optionLabelKey="name"
                                 renderOption={(item) => (
                                     <Box pl={1}>
                                         <Text fontWeight={400} fontSize={14} lineHeight='21px' color={'#323352'}>
                                             {item.name} <Text as="span" color={'#9495A9'}>({item.count})</Text>
                                         </Text>
                                         <Text fontSize="sm" color="gray.500">{item.region}</Text>
                                     </Box>
                                 )}/>
                </VStack>
            </Flex>

            <Flex flexGrow={1} flexDirection={{base: 'column', sm: 'row'}} flexWrap={'wrap'} gap={4} justifyContent={'space-between'}>
                {offers.map((item, index) => {
                    return <MyOfferListItem offer={item} key={index} />
                })}
            </Flex>
        </Flex>
    )
}

export default MyOffersPage;
