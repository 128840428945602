import {Badge, Box, Flex, Text} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {AiOutlineCheck} from "react-icons/ai";
import {useTranslation} from "react-i18next";

const MyRealizedPlanListItem = ({plan, idx}) => {
    const {t} = useTranslation("PlanPage");
    const navigate = useNavigate();

    const handleDetails = () => {
        navigate("/details", {state: plan});
    }

    return (
        <>
            <Flex
                key={idx}
                align="center"
                bg="white"
                p={4}
                borderRadius="12px"
                mb={2}
                boxShadow="sm"
                justify="space-between"
                gap='12px'
                onClick={handleDetails}
                cursor={'pointer'}
            >
                <Flex align="center" gap='12px'>
                    <Box
                        bg="#2A1E8A"
                        color="white"
                        borderRadius="50%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                            <rect width="50" height="50" rx="25" fill="#011938"/>
                            <path d="M19.0652 25C19.0652 20.1675 22.608 16.25 26.9783 16.25H31.5V35H19.0652V25Z" fill="white"/>
                            <path d="M18.5 18.0673L21.135 15.625L23.6767 18.9778L21.0417 21.4202L18.5 18.0673Z" fill="white"/>
                            <path d="M25.2826 15H29.8043V19.375H25.2826V15Z" fill="white"/>
                        </svg>
                    </Box>
                    <Flex flexDirection={"column"} gap='4px'>
                        <Text fontWeight="400" fontSize="12px" color="#323352" lineHeight='18px'>
                            {plan.station}
                        </Text>
                        <Flex align="center" gap={2}>
                            <Badge
                                bg="#00B227"
                                color="white"
                                fontSize="12px"
                                borderRadius="20px"
                                px={2}
                                display="flex"
                                alignItems="center"
                                gap={1}
                                textTransform="none"
                                fontWeight="400" fontSize="12px" lineHeight='18px'
                            >
                                <AiOutlineCheck />
                                {t(`PLAN_STATUS_${plan.status}`)}
                            </Badge>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex direction="column" align="flex-end" fontWeight="500" fontSize="15px" color="#323352" lineHeight='22.5px'>
                    <Text>
                        {plan.litersUsed} l
                    </Text>
                    <Text>
                        {plan.amount} {t('PLN')}
                    </Text>
                </Flex>
            </Flex>
        </>
    )
}

export default MyRealizedPlanListItem;
