import {
  Badge,
  Box,
  Text,
  Flex,
  Link,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import {AiOutlineCheck, AiOutlineFileImage} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import utils from "../../../components/helpers/FormatHelpers";
import {useLocation} from "react-router-dom";
import book from '../../../assets/images/book.jpeg'
import {useMemo, useState} from "react";
import {BASE_URL} from "../../../config";

const DetailsPlanPage = () => {
  const {t} = useTranslation("PlanPage");
  const location = useLocation();
  const plan = location.state;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [image, setImage] = useState();

  const imagePreview = useMemo(() => {
    if (plan?.imageUri) {
      setImage(plan.imageUri);
    }
    if (!image) {
      return '';
    }
    return ( image instanceof Blob ) ? URL.createObjectURL(image) : `${BASE_URL}/${image}`;
  }, [image, plan]);

  return (
    <Box
      bg="#F0F9FD"
      w={'80%'}
      mx="auto"
      mt={10}
      borderRadius="16px"
      boxShadow="md"
      fontFamily="Ubuntu, sans-serif"
    >
      <Text fontSize="20px" fontWeight="700" textAlign="left" mb={6} color="#323352" bg={'white'} p='16px'>
        {t('DETAILS_TITLE')}
      </Text>

      <Flex justifyContent="space-between" px='16px' pb='16px'>
        <Box>
          <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#9495A9">
            {t('QUANTITY')}
          </Text>
          <Text fontWeight="500" fontSize="18px" lineHeight='27px' color="#323352">
            {plan?.litersUsed} l
          </Text>
        </Box>
        <Box textAlign="right">
          <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#9495A9">
            {t('AMOUNT')}
          </Text>
          <Text fontWeight="500" fontSize="18px" lineHeight='27px' color="#323352">
            {plan?.amount} {t('PLN')}
          </Text>
        </Box>
      </Flex>

      <Flex justifyContent="space-between" px='16px' pb='16px'>
        <Box>
          <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#9495A9">
            {t('TRANSACTION_DATE')}
          </Text>
          <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#323352">
            {utils.dateFormat(plan?.createdAtUtc, 'DD.MM.YYYY')}
          </Text>
        </Box>
        <Box textAlign="right">
          <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#9495A9">
            {t('STATUS')}
          </Text>
          <Badge
              colorScheme="green"
              color="#00B227"
              textTransform="none"
              display={'flex'}
              alignItems={'center'}
              gap={'4px'}
              p={0}
              fontWeight="400" fontSize="14px" lineHeight='21px'>
            <AiOutlineCheck />
            {t(`PLAN_STATUS_${plan?.status}`)}
          </Badge>
        </Box>
      </Flex>

      <Box px='16px' pb='16px'>
        <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#9495A9" mb={1}>
          {t('STATION')}
        </Text>
        <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#323352">
          {plan?.station}
        </Text>
      </Box>

      <Box px='16px' pb='16px'>

        <Flex alignItems="center" justifyContent="space-between">
          <Box>
            <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#9495A9" mb={1}>
              {t('RECEIPT_PHOTO')}
            </Text>
            <Link
                color="#011938"
                fontSize="15px"
                fontWeight="500"
                lineHeight="22.5px"
                onClick={onOpen}
                textDecoration={"underline"}
                _hover={{ textDecoration: "underline" }}
            >
              {t('DISPLAY_PHOTO')}
            </Link>
          </Box>
          <Image src={imagePreview} w={160} ml="auto" />
        </Flex>
      </Box>

      <Box px='16px' pb='16px'>
        <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#9495A9" mb={1}>
          {t('REFERENCE_NUMBER')}
        </Text>
        <Text fontWeight="400" fontSize="14px" lineHeight='21px' color="#323352">
          {plan?.referenceNumber}
        </Text>
      </Box>

      <Box textAlign="center" px='16px' pb='16px'>
        <Text fontWeight="400" fontSize="14px" lineHeight='21px'>
          {t('CONTACT_MESSAGE')}
        </Text>
        <Link
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="#595959"
            fontSize="15px"
            fontWeight="500"
            lineHeight='21px'
            textDecoration={"underline"}
            _hover={{ textDecoration: "underline" }}
        >
          {t('CONTACT')}
        </Link>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('PHOTO')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={imagePreview} alt={t('PHOTO')} width="100%" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DetailsPlanPage;
