import {
    Box,
    Button,
    Text,
    Flex,
    VStack,
    Link,
    Divider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Image
} from "@chakra-ui/react";
import {useEffect, useMemo, useState} from "react";
import {useAuth} from "../../providers/AuthProvider";
import {useTranslation} from "react-i18next";
import EditableField from "../../components/EditableField/EditableField";
import EditableDateField from "../../components/EditableDateField/EditableDateField";
import utils from "../../components/helpers/FormatHelpers";
import info from "../../assets/images/i.svg";

const MyAccountPage = () => {
    const {t} = useTranslation("AccountPage");
    const {login, user, update, remove} = useAuth();
    const [isInitialized, setIsInitialized] = useState(false);
    const {isOpen, onOpen, onClose} = useDisclosure();

    const labels = {
        edit: t('EDIT'),
        save: t('SAVE'),
        cancel: t('CANCEL'),
        unselected: t('UNSELECTED'),
        choose: t('CHOOSE'),
        chooseFile: t('CHOOSE_FILE'),
        city: t('CITY_LABEL'),
        street: t('STREET_LABEL'),
        postalCode: t('POSTALCODE_LABEL')
    };

    const [formData, setFormData] = useState({
        email: user?.email,
        name: user?.name,
        phone: user?.phone,
        registrationNumber: user?.registrationNumber,
        birthDate: user?.birthDate,
        password: '',
        accountName: user?.accountName,
        accountNumber: user?.accountNumber,
        address: user?.address
    });

    const date = useMemo(() => {
        return utils.dateFormat(user?.birthDate, 'YYYY-MM-DD');
    }, [user]);

    const handleFieldSubmit = (fieldName, value) => {
        const val = value?.value || value;
        setFormData((prev) => ({
            ...prev,
            [fieldName]: val,
        }));
    };

    useEffect(() => {
        if (isInitialized) {
            update(formData);
        } else {
            setIsInitialized(true);
        }
    }, [formData]);

    const handleRemove = () => {
        remove();
        onClose();
    };

    return (
        <>
            <Flex w={'100%'} flexDirection={"column"} p={4}>
                <Text fontSize="16px" fontWeight="500" lineHeight={'24px'} color="#323352" textAlign={"center"} bg={"white"} p={4}>
                    {t('USER_PROFILE_LABEL')}
                </Text>
                <Box
                    bg="#F0F9FD"
                    p={6}
                    w={'full'}
                    mx="auto"
                    boxShadow="md"
                    fontFamily="Ubuntu, sans-serif"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight={'18.38px'}
                    gap={'24px'}
                >

                    <Text fontSize="18px" fontWeight="500" lineHeight={'24px'} mb={4} color="#323352">
                        {t('BASIC_LABEL')}
                    </Text>
                    <VStack spacing={4} align="stretch" mb={6} pb={'64px'}>
                        <EditableField
                            label={t('CLIENT_NUMBER_LABEL')}
                            value={user?.userId}
                            name="userId"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                            readonly={true}
                        />

                        <Divider />

                        <EditableField
                            label={t('EMAIL_LABEL')}
                            value={user?.email}
                            name="email"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                        />

                        <Divider />

                        <EditableField
                            label={t('CLIENT_NAME_LABEL')}
                            value={user?.name}
                            name="name"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                        />

                        <Divider />

                        <EditableField
                            label={t('PHONE_LABEL')}
                            value={user?.phone}
                            name="phone"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                        />

                        <Divider />

                        <EditableDateField
                            label={t('BIRTH_DATE_LABEL')}
                            value={date}
                            name="birthDate"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                        />

                        <Divider />

                        <EditableField
                            label={t('REGISTER_NUMBER_LABEL')}
                            value={user?.registrationNumber}
                            name="registrationNumber"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                        />

                    </VStack>
                </Box>

                <Box
                    bg="#F0F9FD"
                    p={6}
                    w={'full'}
                    mx="auto"
                    boxShadow="md"
                    fontFamily="Ubuntu, sans-serif"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight={'18.38px'}
                    gap={'24px'}
                >

                    <Text fontSize="20px" fontWeight="700" lineHeight={'22.98px'} mb={4} color="#323352">
                        {t('PAYMENT_LABEL')}
                    </Text>
                    <VStack spacing={4} align="stretch" mb={6} pb={'64px'}>

                        <EditableField
                            label={t('ACCOUNT_NUMBER_LABEL')}
                            value={user?.accountNumber}
                            name="accountNumber"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                        />

                        <Divider />

                        <EditableField
                            label={t('OWNER_NAME_LABEL')}
                            value={user?.accountName}
                            name="accountName"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                        />

                        <Divider />

                        <EditableField
                            label={t('ADDRESS_LABEL')}
                            value={user?.address}
                            name="address"
                            onSubmit={handleFieldSubmit}
                            labels={labels}
                        />

                        <Divider />



                    </VStack>

                    <Flex justifyContent="space-between" w="100%">
                        <Link
                            color="#2A1E8A"
                            fontWeight="500"
                            fontSize="14px"
                            w="100%"
                            textAlign="right"
                            pt={8}
                            onClick={onOpen}
                        >
                            Usuń konto
                        </Link>
                    </Flex>
                </Box>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#FFFFFF'} borderRadius="16px" mx={{ base: 2, md: 0 }} px={{ base: 1, md: 0 }}>
                    <ModalHeader>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box w={'100%'} display="flex" flexDirection={"column"} alignItems="center" gap={'16px'}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                bg="#FFFFFF"
                                color="#323352"
                                fontFamily={'Poppins'}
                                fontWeight={700}
                                borderRadius="16px"
                                p="16px"
                                w='100%'
                            >
                                <Box w={'100%'} display="flex" flexDirection={"column"} alignItems="center" gap={'16px'}>
                                    <Flex direction="column" alignItems="center" justifyContent="center" textAlign="center">
                                        <Image src={info} alt="Info" w={'100px'} pb={'16px'} />
                                        <Text fontSize="14px" fontWeight="500" lineHeight={'21px'}>
                                            {t('REMOVE_CONFIRM')}
                                        </Text>
                                    </Flex>
                                </Box>
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter display="flex" flexDirection="column" alignItems="center" w="100%">
                        <Button color="primary" variant="solid" _hover={{bg: 'green.500'}} py={6} px={14} fontSize={16} fontWeight={700} mt={3}
                                bg={'green'} borderRadius={32} w={'100%'} onClick={handleRemove}>
                            {t('YES')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MyAccountPage;
