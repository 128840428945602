import {Box, Text} from "@chakra-ui/react";
import MyRealizedPlanListItem from "../MyPlanListItem/MyRealizedPlanListItem";

const MyRealizedPlanList = ({group, index}) => {

    return (
        <Box key={index}>
        <Text fontWeight="400" fontSize="18px" color="#878787" lineHeight='27px' mb={4} fontFamily={'Poppins'}>
            {group?.day}.{group?.month}.{group?.year}
        </Text>
        {group?.plans?.map((plan, idx) => {
            return <MyRealizedPlanListItem plan={plan} idx={idx} />
        })}
    </Box>
    )
}

export default MyRealizedPlanList;
