import {apiClient} from "../apiClient";
import QueryHelpers from "../../components/helpers/QueryHelpers";

const reserve = (value) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const form = new FormData();
    form.append('litersUsed', value);
    form.append('userId', user.id);

    return apiClient.post('/plans', form, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${user.bearerToken}`
        }
    });
}

const getPlans = (values) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const params = {
        pageSize: 100,
        page: 1,
        sortOrder: "desc",
        status: values?.status,
        userId: user.id
    }
    const query = QueryHelpers.buildQueryString(params);
    return apiClient.get(`/plans?${query}`);
}

const getHistoryPlans = (values) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const params = {
        pageSize: 100,
        page: 1,
        sortOrder: "desc",
        status: values?.status,
        userId: user.id
    }
    const query = QueryHelpers.buildQueryString(params);
    return apiClient.get(`/plans/history?${query}`);
}

const getPlan = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const params = {
        pageSize: 1,
        page: 1,
        sortOrder: "desc",
        planId: id,
        userId: user.id
    }
    const query = QueryHelpers.buildQueryString(params);
    return apiClient.get(`/plans?${query}`);
}

const realize = (values, image) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const form = new FormData();
    form.append('userId', user.id);
    form.append('planId', values?.id);
    form.append('station', values?.station);
    form.append('amount', values?.amount);
    form.append('image', image);

    return apiClient.post('/plans/realize', form, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${user.bearerToken}`
        }
    });
}

export default {
    reserve,
    getHistoryPlans,
    getPlans,
    getPlan,
    realize
}
