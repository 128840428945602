import {useTranslation} from "react-i18next";
import {useAuth} from "../../providers/AuthProvider";
import {
    Box,
    Button,
    Text,
    Stack,
    Image, Flex
} from '@chakra-ui/react';
import logo from '../../assets/images/logo_dark.png'
import {useLocation, useNavigate} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import LiterField from "../../components/LiterField/LiterField";
import PlansService from "../../api/services/PlansService";

const PlanPage = () => {
    const {t} = useTranslation("PlanPage");
    const {login, user} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.state?.url ?? '/';

    const handleSubmit = (values, {setSubmitting}) => {
        PlansService.reserve(values?.reward).then(response => {
            if (response.data.isSuccess) {
                setTimeout(() => {
                    handleNavigate('/plans?index=0');
                }, 2000);
            }
        })
    }
    const handleNavigate = (route) => {
        navigate(route);
    }
    const handleBack = () => {
        navigate(url);
    }
    const initialValues = {
        reward: 1
    };

    return (
        <Flex w={'full'} justifyContent={'center'}>
            <Box
                bg={'#F0F9FD'}
                minW={{base: '100%', sm: 'lg'}}
                m={0}
                mt={{base: 0, sm: 2}}>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({isSubmitting, errors, touched, setFieldValue}) => (
                        <Form>
                            <Stack spacing={4} align="center" mb={8} w={"full"}>
                                <Text
                                    textAlign="center"
                                    lineHeight={{base: '24px', sm: 'sm'}}
                                    fontSize={{base: 16, sm: 'lg'}}
                                    fontFamily={'Poppins'}
                                    fontWeight={600}
                                    color="#323352"
                                    bg={'white'}
                                    width={'100%'}
                                    py='10px'
                                    px='16px'
                                >
                                    {t('TITLE')}
                                </Text>
                                <Flex w={'full'} justifyContent={'center'} alignItems={"center"} flexDirection={'column'} px={{base: 4, sm: 6}}>
                                    <Image src={logo} w={45} pb={6}/>
                                    <Text
                                        textAlign="center"
                                        lineHeight='24px'
                                        fontSize={{base: '16px', sm: 'lg'}}
                                        fontFamily={'Poppins'}
                                        fontWeight={600}
                                        color="#323352"
                                        width={'70%'}
                                    >
                                        {t('INFO')}
                                    </Text>
                                    <Field name="reward">
                                        {({field, form}) => (
                                            <LiterField
                                                {...field}
                                                name={"reward"}
                                                label="l"
                                                errors={form.errors}
                                                touched={form.touched}
                                                onChange={field.onChange}
                                                max={user?.canister.litersAvailable}
                                            />
                                        )}
                                    </Field>
                                    <Box maxW={{base: '100%', sm: 'lg'}} mx={'auto'}>
                                        <Text
                                            textAlign="center"
                                            lineHeight={{base: '21px', sm: 'sm'}}
                                            fontSize={{base: 14, sm: 'lg'}}
                                            fontFamily={'Poppins'}
                                            fontWeight={400}
                                            color="#9495A9"
                                            width={'95%'}
                                        >
                                            {t('MESSAGE')}
                                        </Text>

                                    </Box>
                                </Flex>
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'center'} w={'full'} mt={8} mb={4} gap={'8px'} px={'16px'}>
                                    <Button border={'2px'} borderColor={'#2FEA52'} borderRadius={'40px'} w={'42px'} p={0} onClick={handleBack}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                            <path d="M8 15L1 8M1 8L8 0.999999M1 8L19 8" stroke="#011938" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </Button>
                                    <Button
                                        display={"flex"}
                                        type="submit"
                                        justifySelf="center"
                                        isLoading={isSubmitting}
                                        bg="green"
                                        px={24}
                                        borderRadius={40}
                                        fontWeight={700}
                                        fontSize={{base: 16, sm: 'md'}}
                                        lineHeight={'18.38px'}
                                        color="primary"
                                        h={'42px'}
                                        w={'full'}
                                    >
                                        <Text pr={'10px'}>
                                            {t('PLAN')}
                                        </Text>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                                            <path d="M12 1L19 8M19 8L12 15M19 8H1" stroke="#011938" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </Button>
                                </Box>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Flex>
    )
}

export default PlanPage;
